<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="6">
                    <b-form-group :label="$t('fee')">
                            <b-input-group class="mb-3" >
                                <b-form-input
                                    readonly
                                    v-model="fee"
                                >
                                </b-form-input>
                            </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group :label="$t('airport')">
                        <ValidationProvider
                            name="airport"
                            rules="required"
                            v-slot="{ valid, errors }"
                        >
                            <b-input-group class="mb-3" >
                                <b-form-input
                                    type="text"
                                    readonly
                                    v-model="request_data.airport"
                                    :class="errors[0] ? 'is-invalid' : ''"
                                >
                                </b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                                class="mb-2"
                            ></b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <ValidationProvider name="arrival_date" rules="required" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('arrival_date')">
                            <select-date :is-time="true" v-model="request_data.arrival_date"
                                         :validation-error="errors[0]"
                                         :time="true"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="6">
                    <b-form-group :label="$t('airline_name')">
                        <ValidationProvider
                            name="airline_name"
                            rules="required"
                            v-slot="{ valid, errors }"
                        >
                            <b-input-group class="mb-3" >
                                <b-form-input
                                    v-model="request_data.airline_name"
                                    :class="errors[0] ? 'is-invalid' : ''"
                                >
                                </b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                                class="mb-2"
                            ></b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group :label="$t('flight_code')">
                        <ValidationProvider
                            name="flight_code"
                            rules="required"
                            v-slot="{ valid, errors }"
                        >
                            <b-input-group class="mb-3" >
                                <b-form-input
                                    v-model="request_data.flight_code"
                                    :class="errors[0] ? 'is-invalid' : ''"
                                >
                                </b-form-input>
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                                class="mb-2"
                            ></b-form-invalid-feedback>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>



            </b-row>
        </ValidationObserver>
        <b-col cols="12" class="pl-0">
            <b-button
                @click="createForm"
                type="button"
                variant="primary"
                class="btn-lg mr-2"
            >
                {{ $t("save") | toUpperCase }}
            </b-button>
        </b-col>
    </div>
</template>
<script>
import HousingAirportReservationService from "@/services/HousingAirportReservationService";
import moment from "moment";


export default {
    props:{
        formData:{
            type:Object,
        }
    },
    data(){
        return {
          request_data:{
              airport:'Dulles International Airport'
          }
        }
    },
    computed:{
        fee(){
          return this.currencyFormat(this.formData.fee?this.formData.fee:0)
        }
    },
    methods:{
        async createForm(){
             const isValid=this.$refs.formModalValidate.validate()
            if (!isValid){
                return
            }
            this.request_data.arrival_date=moment(this.request_data.arrival_date).format('MM/DD/YYYY HH:mm')
            const config={
                request_data:{
                    ...this.request_data,

                },
                request_type:'airport_transfer_request'

            }
            HousingAirportReservationService.save(this.$route.params.token,config)
                .then(res=>{
                   if (res.data.success){
                       this.$toast.success(res.data.message)
                       this.$emit('successFormSave',true)
                   }

                }).catch(err=>{
                    this.showErrors(err)
                })


        }
    }
}
</script>
